import React from "react"
import Layout from "../components/layout"
import InnerPageLayout from "../components/inner-page-layout"

export default function Honours() {
  return ( 
    <Layout>
        <section className="container">
        <h1>Honours</h1>
        <InnerPageLayout>
            <img src="hero2.jpg" className="img-fluid mb-4 inner-page-hero" />
            <p className="lead">Congratulations to our league and cup winners!</p>
            <div>          
                <h5>2022/23 Season</h5>
                <p>U10’s Blues - Challenge Cup Winners (Jonathan White)<br/>
                U11’s Blues - Cup Final Winners (Sinisa Savic, Gaz Asomaning)<br/>
                U11’s - Craig Pearson Challenge Cup Winners (Sinisa Savic, Gaz Asomaning)<br/>
                U11’s Girls- Cup Final Winners (Jodi Walsh, Pete Walsh, Liam Hall, Steve Beck)<br/>
                U12’s Blues - GJFL League 3 Runners up (Alex Minett , Duncan Colman)<br/>
                U12’s Colts - HDJFL League 3 Winners (Alex Brennan, Myles Mcfarlane)<br/>
                U14’s - GJFL League 2 Winners (Pete Walsh, Mike Inman, Josh Falkingham)<br/>
                U14’s - HDJFL League 2 Winners (Rob Campey, Kev Brown, Larner Caleb)<br/>
                U16’s - GJFL League 4 Winners (Richard Bland, James Roden)<br/> 
                U16’s - Craig Pearson Challenge Cup Winners (Richard Bland, James Roden)</p>

                <h5>2021/22 Season</h5> 
                <p>U10’s Girls - Cup Final Winners (Jodi Walsh, Pete Walsh, Steve Beck)<br/>
                U12’s - GJFL League 4 Winners (Phil Shaw, Eddy Donnelly, Danny Micklethwaite)<br/>
                U15’s Girls - Cup Final Winners (Kieran Brady, James Burnett)</p>

                <h5>2019/20 Season</h5>
                <p>U8’s Colts - GJFL League 3 Runners up (Ade Whitehouse, Andy White)<br/>
                U11’s Girls - Shield Final Runners up (Sasha Holdsworth)</p>

                <h5>2018/19 Season</h5>
                <p>U0’s - Hull Gala Winners (Pete Walsh, Mike Inman)<br/>
                U8’s - Pudsey Gala Winners (Sinisa Savic) </p>
                
                <h5>2017/18 Season</h5>
                <p>U14’s - GJFL Challenge Trophy (Gary Hanakin)</p>
                
                <h5>2016/17 Season</h5>
                <p>U16’s - Leeds & District Minors Cup Winners (Tony Bailey)<br/>
                U15’s - GJFL League 2 runners up (Andy Simpson, Dave Kear)<br/> 
                U12’s - GJFL League 3 Winners (Terry Moore)</p>
                
                <h5>2014/15 Season</h5>
                <p>U14’s - Leeds & District Cup Winners (Tony Bailey)<br/>
                U16’s - GJFL League 3 Winners (Darren Needham, Dave Comery)</p>
                
                <h5>2013/14 Season</h5>
                <p>U11’s - HDJFL Supplementary Cup Winners (Andy Simpson, Dave Kear)</p>
                
                <h5>2012/13 Season</h5>
                <p>U12’s - GJFL Supplementary Cup Winners (Tony Bailey)</p>
                
                <h5>2011/12 Season</h5>
                <p>U13’s - GJFL League 5 Winners (Darren Needham, Dave Comery)</p>
                
                <h5>2010/11 Season</h5>
                <p>U15 - Craven League 2 Winners (Phil Broadley)</p>
                
                <h5>2009/10</h5>
                <p>U16 - Leeds & District Minors Cup Winners (James Tollefson)</p>
                
                <h5>2008/09 Season</h5>
                <p>U13 - GJFL League 3 Winners (Niki McKenna)<br/>
                U14 - GJFL League 2 Winners (Mark Mcfarlane)</p>
            </div>
        </InnerPageLayout>
      </section>
    </Layout>
  );
}
